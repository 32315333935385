<template>
  <article style="padding-top: 50px">
    <Logo />
    <a-result
      v-if="!data.isExist"
      status="500"
      title="空间不存在"
      sub-title="可能已经被删除，或者管理员取消了邀请链接"
    >
      <a-button
        slot="extra"
        type="primary"
        style="width: 140px"
        @click="$router.push('/')"
      >
        回到我的空间
      </a-button>
    </a-result>
    <div
      v-else-if="showLogin"
      class="ndl-flex ndl-flex--column ndl-flex--middle"
    >
      <div class="ndl-text-heading ndl-margin-xl">
        {{ data.memberName }} 邀请你加入空间
        <span class="ndl-color-blue">「{{ data.spaceName }}」</span>
      </div>
      <LoginForm @on-login="comfirmInvite()" />
    </div>
    <a-result v-else>
      <img slot="icon" :src="ImgStatus" style="height: 300px;" />
      <template slot="title">
        {{ data.memberName }} 邀请你加入空间
        <span class="ndl-color-blue">「{{ data.spaceName }}」</span>
      </template>
      <a-button
        slot="extra"
        type="primary"
        style="width: 140px"
        @click="onConfirm()"
      >
        确认加入
      </a-button>
    </a-result>
  </article>
</template>

<script>
// import { validateInviteCode } from "@/api/invite";
import ImgStatus from "@/assets/images/ai_network_error.svg";
import Logo from "@/views/auth/Logo";
import LoginForm from "@/views/auth/LoginForm";

export default {
  components: { Logo, LoginForm },
  data() {
    return {
      ImgStatus,
      showLogin: false,
      data: {}
    };
  },
  async beforeRouteEnter(to, from, next) {
    const code = to.query.code;
    if (!code) return next({ name: "error-404" });
    // const { data } = await validateInviteCode({ code });
    const data = {
      inviteCode: "18211835",
      isExist: true,
      isLogin: false,
      memberName: "Admin2",
      spaceId: "spcNgTRkfH9KZ",
      spaceName: "debug"
    };
    next(vm => (vm.data = data));
  },
  methods: {
    onConfirm() {
      const data = this.data;
      if (data.isLogin) return this.comfirmInvite();
      this.showLogin = true;
    },
    comfirmInvite() {
      // @todo: 发送确认加入请求
    }
  }
};
</script>
